.red-border {
    border: 1px solid red;
}
body {
    background: url(../assets/cloud-bg.jpeg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.app {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family:Georgia, 'Times New Roman', Times, serif;
}
.header {
    /*border: solid 5px red;*/
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 25px;
}
.glat-logo {
   /* border: solid 5px rgb(0, 255, 21);*/
    background-image: url(../assets/glat-logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 130px;
    height: 130px;
    margin-bottom: 0;
}
.header h1 {
   /* border: solid 5px rgb(0, 255, 21);*/
    margin: 0px;
    margin-bottom: 5px;
    font-size: 30px;
}
.splash-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 50px;
}
button {
    width: 250px;
    padding: 5px;
    margin: 15px;
    background-color: rgb(247, 177, 14);
    color: rgb(111, 10, 10);
    font-weight: bolder;
    font-size: 17px;
    border-radius: 50px;
    border: 2px solid white;
}
.footer {
    /*border: solid 5px red;*/
    width: 90vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 50px;
    font-size: 12px;
    text-align: center;
}
.footer p {
    margin: 5px;
    text-align: center;
    font-size: 12px;
}
.footer a {
    text-decoration: none;
    text-align: center;
    font-size: 12px;
}

/*give now*/
.form-component {
    text-align: center;
}
h2 {
    font-size: 20px;
}
.form-container {
    background: rgba(255, 255, 255, 0.82);
    border: 2px solid white;
    border-radius: 10px;
    max-width: 400px;
    padding: 20px 10px 10px 10px;
}
.form-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 5px 5px 5px;
    text-align: left;
}
.specify-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 0px;
    text-align: left;
    margin: 0;
}


.form-total h3 {
    margin-top: 50px;
    font-size: 20px;
    margin-bottom: 0;
}
.form-total p {
    font-size: 30px;
    margin: 0 0 20px 0;
    padding: 0;
}
.review-pay-component {
    text-align: center;
    margin-top: 0;
}
.review-container {
    background: rgba(255, 255, 255, .6);
    border: 4px solid white;
    border-radius: 10px;
    padding: 10px;
    width: 85vw;
    max-width: 400px;
}
.review-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 5px 15px 5px;
    text-align: left;
    margin: 15px 5px 15px 5px;
    border-bottom: 1px solid black;
}
.review-item p {
    margin: 0;
    padding: 0;
    width: 70vw;
    padding-left: 5px;
}